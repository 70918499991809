import Vuex from "vuex";

import login from "./modules/login";
import { devices } from "./modules/devices/index";

export default new Vuex.Store({
  modules: {
    login,
    devices
  }
});
