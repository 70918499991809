import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state = {
  devices: [],
  selectedDevice: [],
  selectedDate: [],
  measurements: [],
  hashData: null,
  saveOffset: 0,
  sensors: []
};

export const devices = {
  state,
  getters,
  actions,
  mutations
};
