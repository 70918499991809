export const getters = {
    getData(state) {
      return state.devices;
    },
    getSelectedData(state) {
      return state.selectedDevice;
    },
    getSelectedDateData(state) {
      return state.selectedDate;
    },
    getMeasurementsByDeviceData(state) {
      return state.measurements;
    },
    getSensors(state) {
      return state.sensors;
    },
    getHashData(state) {
      return state.hashData;
    },
    getOffset(state) {
      return state.saveOffset;
    }
};