import axios from "axios";

export const actions = {
  async getDevices({ commit }, payload) {
    let url = '/devices';
    if(payload) {
      const query = Object.keys(payload).map(key => {
          return `${key}=${payload[key]}`;
      }).join('&');
      url = `${url}?${query}`;
    }
    const response = await axios.get(
      url,
      { headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }}
    );
    commit("getDevices", response.data);
  },

  async getSelectedDevice({ commit }, payload) {
    const response = await axios.get(
      "/devices/" + payload, 
      { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }}
    );
    commit("getSelectedDevice", response.data);
  },

  async getMeasurementsByDevice({ commit }, payload) {
    let url = "/measurements/device/" + payload.id
    if(payload.search.date != null || payload.search.offset != 0) {
      const query = Object.keys(payload.search).map(key => {
        return `${key}=${payload.search[key]}`;
      }).join('&');
      url = `${url}?${query}`;
    }
    const response = await axios.get(
      url,
      { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }}
    );
    commit("getMeasurementsByDevice", response.data);
  },

  async getSelectedDateDevice({ commit }, payload) {
    let url = `/measurements/device/${payload.id}/${payload.date}`
    if(payload.search.offset) {
      const query = Object.keys(payload.search).map(key => {
        return `${key}=${payload.search[key]}`;
      }).join('&');
      url = `${url}?${query}`;
    }
    const response = await axios.get(
      url, 
      { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }}
    );
    commit("getSelectedDateDevice", response.data);
  },

  async getSensorsConfig({commit}) {
    const response = await axios.get("/devices/config/", 
      { headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }}
    );
    commit("getSensorsConfig", response.data.sensors);
  } 
};