export const mutations = {
    getDevices(state, payload) {
      state.devices = payload;
    },
    getSelectedDevice(state, payload) {
      state.selectedDevice = payload;
    },
    getMeasurementsByDevice(state, payload) {
      state.measurements = payload;
    },
    getSelectedDateDevice(state, payload) {
      state.selectedDate = payload;
    },
    saveHashData(state, payload) {
      state.hashData = payload;
    },
    saveOffset(state, payload) {
      state.saveOffset = payload;
    },
    getSensorsConfig(state, payload) {
      state.sensors = payload;
    }
};
  